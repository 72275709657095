<div>
  <h1 class="page-title">Berichte</h1>
</div>

<label>Von: <input type="date" [(ngModel)]="fromDate" /></label>
<label>Bis: <input type="date" [(ngModel)]="toDate" /></label>
<label>Land: <input type="text" [(ngModel)]="selectedCountry" /></label>
<button mat-button (click)="fetchReports()">Ermittlen</button>

<!-- Bar Chart: Tatbestande Frequency -->

<div class="container-fluid" style="margin-top: 30px">
  <!-- / .row -->
  <div class="row">
    <div class="col-6">
      <h2>Anzahl Anzeigen</h2>
      <!-- Container with a maximum height of 300px -->
      <div style="max-height: 500px; overflow-y: auto">
        <canvas baseChart [data]="tatbestandeChartData" [type]="'bar'" [options]="chartOptions"></canvas>
      </div>
    </div>
    <div class="col-6">
      <!-- Line Chart: Monthly Mandate Count -->
      <h2>Anz. Mandate in den letzten 6 Monaten</h2>
      <div style="max-height: 500px; overflow-y: auto">
        <canvas baseChart [data]="monthlyChartData" [type]="'line'" [options]="chartOptions"></canvas>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" style="margin-top: 30px">
  <!-- / .row -->
  <div class="row">
    <div class="col-6">
      <!-- Tatbestande Table -->
      <h2>Tatbestand angezeigt</h2>
      <table>
        <tr>
          <th>Tatbestand</th>
          <th>Anz. Angezeigt</th>
        </tr>
        <tr *ngFor="let item of reports?.tatbestande | keyvalue">
          <td>{{ item.key }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </table>
    </div>

    <!-- / .row -->
    <div class="col-6">
      <!-- Mandates per Country Table -->
      <h2>Mandate per Länder</h2>
      <table>
        <tr>
          <th>Land</th>
          <th>Anz. Mandate</th>
        </tr>
        <tr *ngFor="let country of reports?.country_count | keyvalue">
          <td>{{ country.key }}</td>
          <td>{{ country.value }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>

<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
