import { Title } from '@angular/platform-browser';
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';

import { AuthDataService } from '@app/auth';
import { AuthQuery } from '@app/auth/auth.query';

import { environment } from './../../environments/environment';
import { Logger } from '@core';

import { JournalService, MailService, Mail } from '@core';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  events: string[] = [];
  opened: boolean;
  appropriateClass: string = '';

  isEmptyNotification = true;

  showExtendedVersion = environment.feature_ZAHLUNGSABGLEICH;

  imageStorage = environment.serverUrl + '/'; // images gehen über api

  @HostListener('window:resize', ['$event'])
  getScreenHeight() {
    //console.log(window.innerHeight);
    if (window.innerHeight <= 412) {
      this.appropriateClass = 'bottomRelative';
    } else {
      this.appropriateClass = 'bottomStick';
    }
  }

  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthDataService,
    private authQuery: AuthQuery,
    private journalService: JournalService
  ) {
    //this.getScreenHeight();
  }

  ngOnInit() {}

  logout() {
    this.authService.logout().subscribe(() => {
      //this.router.navigate(['/login'], { replaceUrl: true });
      // changes mario: 25.3.24
      //probably better: this.router.navigateByUrl('/login');

      // Alternatively, for a hard reset, you might want to reload the entire application
      window.location.href = '/login';
    });
    console.log('logout');
    this.journalService.log('Abmeldung Logout erfolgreich', '', '');
  }

  get role(): number | null {
    return this.authQuery.role;
  }

  get username(): string | null {
    return this.authQuery.name;
  }

  get profile_image(): string | null {
    return this.authQuery.profile_image;
  }

  get email(): string | null {
    return this.authQuery.email;
  }

  get werbungActive(): boolean | false {
    return false;
  }

  /* handle dropdown by click */
  isDropdownOpen = false;
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  /**--- */

  onSideNavOpened() {
    //  do something
  }

  get isMobile(): boolean {
    //return this.media.isActive('xs') || this.media.isActive('sm');
    return false;
  }

  get title(): string {
    return this.titleService.getTitle();
  }
}
