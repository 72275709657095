import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { Shell } from '@app/shell/shell.service';
import { AuthenticationGuard } from '../auth/authentication.guard';
//import { AppDataSyncGuard } from '../auth/syncappdata.guard';

//import { HomeComponent } from './home.component';
import { MandatComponent } from '@app/mandat/mandat.component';
import { ReportsComponent } from './../reports/reports.component';
//import { Modul0StartComponent } from '../modul0-start/modul0-start.component';

import { DashboardComponent } from './../dashboard/dashboard.component';
import { AboutComponent } from '../about/about.component';
import { SettingsComponent } from '../settings/settings.component';
import { HelpComponent } from '@app/help/help.component';
import { ImpressumComponent } from '@app/impressum/impressum.component';
import { DatenschutzComponent } from '@app/datenschutz/datenschutz.component';
import { SupportComponent } from '@app/support/support.component';
import { StammdatenComponent } from '@app/stammdaten/stammdaten.component';

import { TransferComponent } from '@app/transfer/transfer.component';
import { VSTVComponent } from '@app/vstv/vstv.component';
import { MandatEditComponent } from '../mandat_edit/mandat_edit.component';
import { TransferEditComponent } from '../transfer_edit/transfer_edit.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    {
      path: 'dashboard',
      canActivate: [AuthenticationGuard],
      component: DashboardComponent,
      data: { title: extract('Dashboard') },
    },
    {
      path: 'mandate',
      canActivate: [AuthenticationGuard],
      component: MandatComponent,
      data: { title: 'Mandate' },
      // List and form component is the child component of sidebar3 component.
      children: [
        {
          // When you click go to list, it navigate to list component page.
          path: 'edit',
          canActivate: [AuthenticationGuard],
          component: MandatEditComponent,
        },
      ],
    },

    {
      path: 'transfer',
      canActivate: [AuthenticationGuard],
      component: TransferComponent,
      data: { title: 'Transfer' },
      // List and form component is the child component of sidebar3 component.
      children: [
        {
          // When you click go to list, it navigate to list component page.
          path: 'edit',
          canActivate: [AuthenticationGuard],
          component: TransferEditComponent,
        },
      ],
    },

    {
      path: 'vstv',
      canActivate: [AuthenticationGuard],
      component: VSTVComponent,
      data: { title: 'Transfer' },
    },

    {
      path: 'reports',
      canActivate: [AuthenticationGuard],
      component: ReportsComponent,
      data: { title: 'Reports' },
    },

    { path: 'about', canActivate: [AuthenticationGuard], component: AboutComponent, data: { title: extract('About') } },
    {
      path: 'settings',
      canActivate: [AuthenticationGuard],
      component: SettingsComponent,
      data: { title: extract('Settings') },
    },
    {
      path: 'stammdaten',
      canActivate: [AuthenticationGuard],
      component: StammdatenComponent,
      data: { title: extract('Stammdaten') },
    },
    {
      path: 'datenschutz',
      canActivate: [],
      component: DatenschutzComponent,
      data: { title: extract('Datenschutz') },
    },
    {
      path: 'impressum',
      canActivate: [],
      component: ImpressumComponent,
      data: { title: extract('Impressum') },
    },
    { path: 'help', canActivate: [AuthenticationGuard], component: HelpComponent, data: { title: extract('Help') } },
    {
      path: 'support',
      canActivate: [AuthenticationGuard],
      component: SupportComponent,
      data: { title: extract('Support') },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ShellRoutingModule {}
